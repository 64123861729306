import * as React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import DropDown from '../DropDown';
import { getTokenFromState, getActiveSeriesIdFromState } from '../../redux/state-utils'
import { fetchEpisodeList, deleteEpisode } from '../../apiService'
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { useSelector, useDispatch } from 'react-redux'
import { setActiveEpisode } from '../../redux/useAction'
import CreateEpisode from './CreateEpisode'
import UpdateEpisode from './UpdateEpisode'
import { Confirm } from '../common/Confirm'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

export default function Episode() {
    const [episode, setEpisode] = React.useState(null);
    const [epsiodeList, setEpisodeList] = React.useState([]);
    const [mode, setMode] = React.useState("");
    const seriesId = useSelector(state => getActiveSeriesIdFromState(state))
    const token = useSelector(state => getTokenFromState(state))
    const dispatch = useDispatch()
    const onCreateEpisode = () => {
        return fetchEpisodeListFunc()
    }
    const onUpdateEpisode = () => {
        return fetchEpisodeListFunc()
    }

    const onDeleteEpisode = () => {
        deleteEpisode(episode.episodeId, token).then(data => {
            if (data.status !== 200) {
                return Promise.reject()
            }
            console.log("Deleted episode " + episode.episodeId)
        }).then(res => {
            return fetchEpisodeListFunc()
        }).catch(err => {
            console.error(err.message)
            alert("Something went wrong!!!")
        })
    }

    const fetchEpisodeListFunc = React.useCallback(() => {
        return fetchEpisodeList(seriesId, token).then(data => {
            if (data.status === 200) {
                return data.json()
            } else {
                return Promise.reject("Error in fetchEpisodeList")
            }
        }).then(res => {
            setEpisodeList(res)
        }).catch(err => {
            console.error(err)
            alert(err)
        })
    }, [seriesId, token])
    React.useEffect(() => {
        fetchEpisodeListFunc()
    }, [seriesId, token, fetchEpisodeListFunc])

    const handleSetEpisode = (selectedEpisode) => {
        setEpisode(selectedEpisode)
    }

    const selectEpisodeClick = () => {
        dispatch(setActiveEpisode(episode))
    }

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={11}>
                <Card variant="outlined">
                    <Grid container spacing={2} justifyContent="center" alignItems="end">
                        <Grid item xs={10}>
                            <Grid container spacing={2} justifyContent="left" alignItems="end">
                                <Grid item xs={6}>
                                    <DropDown id="episode" label="Episode" values={epsiodeList}
                                        value={episode} handleChange={handleSetEpisode}
                                        getUniqueId={(option) => option.episodeId}
                                        getDisplayName={(option) => option.name}
                                        isError={false}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <AddOutlinedIcon onClick={() => setMode("create")} />
                                </Grid>
                                {episode && <Grid item xs={2}>
                                    <EditOutlinedIcon onClick={() => setMode("update")} />
                                </Grid>}
                                {episode && <Grid item xs={2}>
                                    <DeleteOutlineOutlinedIcon onClick={() => setMode("delete")} />
                                </Grid>}
                                <Grid item xs={6}>
                                    <Button variant="contained" fullWidth
                                        onClick={selectEpisodeClick}>
                                        Next
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        {mode === "create" && <Grid item xs={10}>
                            <CreateEpisode onCreateEpisode={onCreateEpisode} />
                        </Grid>}
                        {mode === "update" && episode && <Grid item xs={10}>
                            <UpdateEpisode episode={episode}
                                onUpdateEpisode={onUpdateEpisode} />
                        </Grid>}
                        {mode === "delete" && episode && <Grid item xs={10}>
                            <Confirm heading={`Are you sure you want to delete ${episode.name}`}
                                onSuccess={onDeleteEpisode}
                                onFailure={() => setMode("")}
                            />
                        </Grid>}
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    );
}