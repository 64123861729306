import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function DropDown(props) {
    const handleChange = (event) => {
        let value = event.target.value;
        if (value) {
            props.handleChange(props.values.find(p => props.getUniqueId(p) === value));
        }
    }
    return (
        <FormControl variant="standard" fullWidth>
            <InputLabel id={props.id}>{props.label}</InputLabel>
            <Select
                error={!!props.isError}
                labelId={props.id + "select-label"}
                id={props.id + "select"}
                value={props.value ? props.getUniqueId(props.value) : ""}
                onChange={handleChange}
                label={props.label}
            >
                {props.values && props.values.map((value, i) => {
                    return <MenuItem key={i} value={props.getUniqueId(value)}>{props.getDisplayName(value)}</MenuItem>
                })}
            </Select>
        </FormControl>
    );
}