import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
export function Confirm(props) {
    const heading = props.heading
    const onSuccess = props.onSuccess
    const onFailure = props.onFailure
    return <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10}>
            <Typography gutterBottom variant="h6" component="div" align='center' fontWeight="bold">
                {heading}
            </Typography>
        </Grid>
        <Grid item xs={5}>
            <Button variant="contained" fullWidth={true} onClick={onSuccess} color="success">
                Yes
            </Button>
        </Grid>
        <Grid item xs={5}>
            <Button variant="contained" fullWidth={true} onClick={onFailure} color="error">
                No
            </Button>
        </Grid>
    </Grid>
}