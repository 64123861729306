import * as React from 'react';
import Box from '@mui/material/Box';

export default function RobotImage(props) {
    const [objUrl, setObjUrl] = React.useState("")
    let style = { width: "100%" }
    if (props.aspectRatio) {
        style = { ...style, "aspect-ratio": "" + props.aspectRatio }
    }
    const file = props.file
    React.useEffect(() => {
        if (file) {
            setObjUrl(URL.createObjectURL(file))
        }
    }, [file])
    if (props.imageUrl || objUrl) {
        return <Box
            bgcolor="blue"
            component="img"
            sx={style}
            alt="Image not found"
            src={props.imageUrl ? props.imageUrl : objUrl}
        />
    }
    return <>
        <Box
            bgcolor="blue"
            component="img"
            alt="Image not found"
            sx={{ ...style, display: { xs: 'none', md: 'block' } }}
            src={props.imageUrl ? props.imageUrl : objUrl ? objUrl : "/static/images/rocket-desktop.png"}
        />
        <Box
            bgcolor="blue"
            component="img"
            alt="Image not found"
            sx={{ ...style, display: { xs: 'block', md: 'none' } }}
            src={props.imageUrl ? props.imageUrl : objUrl ? objUrl : "/static/images/rocket-mobile.png"}
        />
    </>

}