import * as React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import RobotImage from './RobotImage';
import Typography from '@mui/material/Typography';
import { authenticate } from '../apiService';

export default function Login(props) {
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [formSubmitted, setFormSubmitted] = React.useState(false);
    const onSubmit = () => {
        setFormSubmitted(true)
        authenticate(username, password).then(data => {
            return data.json();
        }).then(res => {
            props.onLogin(res)
        })
            .catch(err => {
                setErrorMessage("Please check the credentials")
            })
    }
    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
                <Card variant="outlined">
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item md={5}>
                            <RobotImage />
                        </Grid>
                        <Grid item md={7}>
                            <Grid container spacing={4} justifyContent="center" alignContent="center"
                                height="100%"
                            >
                                <Grid item xs={7}>
                                    <TextField
                                        fullWidth={true}
                                        id="username"
                                        label="Username"
                                        type="text"
                                        autoComplete="username"
                                        variant="standard"
                                        value={username}
                                        onChange={(event) => { setUsername(event.target.value) }}
                                        error={formSubmitted && !username}
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                    <TextField
                                        fullWidth={true}
                                        id="password"
                                        label="Password"
                                        type="password"
                                        autoComplete="password"
                                        variant="standard"
                                        value={password}
                                        onChange={(event) => { setPassword(event.target.value) }}
                                        error={formSubmitted && !password}
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                    <Button variant="contained" endIcon={<SendIcon />} onClick={onSubmit}>
                                        Login
                                    </Button>
                                </Grid>
                                {errorMessage && <Grid item xs={7}>
                                    <Typography gutterBottom variant="h6" component="div" align='center' color={"red"} fontWeight="bold">
                                        {errorMessage}
                                    </Typography>
                                </Grid>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    );
}