import * as React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import RobotImage from '../RobotImage';
import DropDown from '../DropDown';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useSelector } from 'react-redux'
import { getTokenFromState } from '../../redux/state-utils'
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

export default function EntityWithNameComponent(props) {
    const [entityList, setEntityList] = React.useState([])
    const [entity, setEntity] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [dialogMode, setDialogMode] = React.useState("")
    const token = useSelector(state => getTokenFromState(state))
    const createNewEntity = props.createNewEntity;
    const updateEntityProp = props.updateEntity;
    const deleteEntityProp = props.deleteEntity;
    const setActiveEntity = props.setActiveEntity
    const entityText = props.entityText
    const fetchEntityList = props.fetchEntityList;
    const fetchEntityListFunc = React.useCallback(() => {
        return fetchEntityList(token).then(res => {
            setEntityList(res);
        }).catch(err => {
            setErrorMessage("Something went wrong while fetching " + entityText + " list")
        })
    }, [token, entityText, fetchEntityList])
    //TODO add retry
    React.useEffect(() => {
        fetchEntityListFunc()
    }, [token, fetchEntityList, entityText, fetchEntityListFunc])
    const selectEntityClick = () => {
        setActiveEntity(entity)
    }
    const updateEntity = (newEntityName, entity, token) => {
        return updateEntityProp(newEntityName, entity, token).then(() => {
            return fetchEntityListFunc().then(r => {
                setDialogMode("")
                setEntity(null)
            });
        })
    }
    const deleteEntity = () => {
        return deleteEntityProp(entity, token).then(() => {
            return fetchEntityListFunc().then(r => {
                setDialogMode("")
                setEntity(null)
            });
        })
    }
    const onEditClick = () => {
        setDialogMode("update")
    }

    const onCreateClick = () => {
        setDialogMode("create")
    }

    const onDeleteClick = () => {
        setDialogMode("delete")
    }
    const handleDialogClose = () => {
        setDialogMode("")
    }
    return (
        <>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={11}>
                    <Card variant="outlined">
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item md={6}>
                                <RobotImage />
                            </Grid>
                            <Grid item md={6}>
                                <Grid container spacing={2} justifyContent="left" alignItems="end">
                                    <Grid item xs={6}>
                                        <DropDown id={entityText} label={entityText} values={entityList}
                                            value={entity} handleChange={setEntity}
                                            getUniqueId={(option) => props.getUniqueId(option)}
                                            getDisplayName={(option) => props.getDisplayName(option)}
                                            isError={false}
                                        />
                                    </Grid>
                                    {entity && <Grid item xs={2}>
                                        <EditOutlinedIcon onClick={onEditClick} fontSize="medium" />
                                    </Grid>}
                                    {entity && <Grid item xs={2}>
                                        <DeleteOutlineOutlinedIcon onClick={onDeleteClick} fontSize="medium" />
                                    </Grid>}
                                    <Grid item xs={2}>
                                        <AddOutlinedIcon onClick={onCreateClick} fontSize="medium" />
                                    </Grid>
                                    {/* <Grid item xs={6}>
                                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                                            {entity && <Grid item xs={2}>
                                                <EditOutlinedIcon onClick={onEditClick} />
                                            </Grid>}
                                            {entity && <Grid item xs={2}>
                                                <DeleteOutlineOutlinedIcon onClick={onDeleteClick} />
                                            </Grid>}
                                            <Grid item xs={2}>
                                                <AddOutlinedIcon onClick={onCreateClick} />
                                            </Grid>
                                        </Grid>
                                    </Grid> */}
                                    <Grid item xs={5}>
                                        <Button variant="contained" fullWidth={true} endIcon={<SendIcon />}
                                            onClick={selectEntityClick}>
                                            Next
                                        </Button>
                                    </Grid>
                                    {errorMessage && <Grid item xs={10}>
                                        <Typography gutterBottom variant="h6" component="div" align='center' color={"red"} fontWeight="bold">
                                            {errorMessage}
                                        </Typography>
                                    </Grid>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
            <Dialog onClose={handleDialogClose} open={!!dialogMode}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={10}>
                        <Typography gutterBottom variant="h6" component="div" align='center' fontWeight="bold">
                            {dialogMode && dialogMode.toUpperCase()} {entityText.toUpperCase()}
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        {dialogMode === "create" && <CreateEntityComponent
                            createNewEntity={createNewEntity}
                            entityText={entityText}
                        />}
                        {dialogMode === "update" && entity && <UpdateEntityComponent
                            updateEntity={updateEntity}
                            entity={entity}
                            entityText={entityText}
                            entityList={entityList}
                            getUniqueId={(option) => props.getUniqueId(option)}
                            getDisplayName={(option) => props.getDisplayName(option)}
                        />}
                        {dialogMode === "delete" && entity && <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={10}>
                                <Typography gutterBottom variant="h6" component="div" align='center' fontWeight="bold">
                                    Are you sure you want to delete {props.getDisplayName(entity)}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Button variant="contained" fullWidth={true} onClick={deleteEntity} color="success">
                                    Yes
                                </Button>
                            </Grid>
                            <Grid item xs={5}>
                                <Button variant="contained" fullWidth={true}
                                    onClick={() => setDialogMode("")} color="error">
                                    No
                                </Button>
                            </Grid>
                        </Grid>}
                    </Grid>
                </Grid>
            </Dialog>
        </>
    );
}

function CreateEntityComponent(props) {
    const [errorMessage, setErrorMessage] = React.useState("");
    const [newEntityName, setNewEntityName] = React.useState("");
    const createNewEntity = props.createNewEntity;
    const entityText = props.entityText
    const token = useSelector(state => getTokenFromState(state))
    const handleCreateEntityClick = () => {
        if (!newEntityName) {
            return;
        }
        createNewEntity(newEntityName, token).then(res => {
            setErrorMessage("")
        }).catch(err => {
            setErrorMessage("Error creating new " + entityText)
        })
    }
    return <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
            <TextField
                fullWidth={true}
                id={entityText}
                label={"New " + entityText}
                type="text"
                autoComplete={entityText}
                variant="standard"
                value={newEntityName}
                onChange={(event) => { setNewEntityName(event.target.value) }}
                error={false}
            />
        </Grid>
        <Grid item xs={12}>
            <Button variant="contained" endIcon={<SendIcon />}
                onClick={handleCreateEntityClick}>
                Create New {entityText}
            </Button>
        </Grid>
        {errorMessage && <Grid item xs={12}>
            <Typography gutterBottom variant="h6" component="div" align='center' color={"red"} fontWeight="bold">
                {errorMessage}
            </Typography>
        </Grid>}
    </Grid>
}

function UpdateEntityComponent(props) {
    const [errorMessage, setErrorMessage] = React.useState("");
    const [newEntityName, setNewEntityName] = React.useState(props.getDisplayName(props.entity));
    const updateEntity = props.updateEntity;
    const entityText = props.entityText
    const token = useSelector(state => getTokenFromState(state))
    const entity = props.entity
    const handleUpdateEntityClick = () => {
        if (!newEntityName) {
            return;
        }
        updateEntity(newEntityName, entity, token).then(res => {
            setErrorMessage("")
        }).catch(err => {
            setErrorMessage("Error updating " + entityText)
        })
    }
    return <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
            <Typography gutterBottom variant="h6" component="div" align='center' fontWeight="bold">
                Update {props.getDisplayName(entity)}
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <TextField
                fullWidth={true}
                id={entityText}
                label={"Updated " + entityText + " Name"}
                type="text"
                autoComplete={entityText}
                variant="standard"
                value={newEntityName}
                onChange={(event) => { setNewEntityName(event.target.value) }}
                error={false}
            />
        </Grid>
        <Grid item xs={12}>
            <Button variant="contained" endIcon={<SendIcon />}
                onClick={handleUpdateEntityClick}>
                Update {entityText}
            </Button>
        </Grid>
        {errorMessage && <Grid item xs={12}>
            <Typography gutterBottom variant="h6" component="div" align='center' color={"red"} fontWeight="bold">
                {errorMessage}
            </Typography>
        </Grid>}
    </Grid>
}