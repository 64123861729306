import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { fetchProgramList, createNewProgram, updateProgram, deleteProgram } from '../../apiService'
import { setActiveProgram } from '../../redux/useAction'
import { useNavigate } from 'react-router';
import EntityWithNameComponent from '../common/EntityWithNameComponent'
import { getPublisherIdFromState } from '../../redux/state-utils'

export default function Program() {
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const publisherId = useSelector(state => getPublisherIdFromState(state))
    const handleCreateProgramClick = (newProgramName, token) => {
        return createNewProgram(newProgramName, publisherId, token)
            .then(data => {
                if (data.status !== 200) {
                    return Promise.reject();
                } else {
                    return data.json()
                }
            }).then(res => {
                selectProgramClick(res)
            })
    }
    const handleUpdateProgramClick = (newProgramName, program, token) => {
        return updateProgram(newProgramName, program.programId, token)
            .then(data => {
                if (data.status !== 200) {
                    return Promise.reject();
                }
                console.log("Updated newProgramName " + newProgramName)
            })
    }
    const handleDeleteProgramClick = (program, token) => {
        return deleteProgram(program.programId, token)
            .then(data => {
                if (data.status !== 200) {
                    return Promise.reject();
                }
                console.log("Delete program " + program.programId)
            })
    }
    const selectProgramClick = (program) => {
        dispatch(setActiveProgram(program))
        navigate(`/variant`)
    }
    const fetchProgramListFunc = (token) => {
        return fetchProgramList(publisherId, token).then(data => {
            if (data.status !== 200) {
                return Promise.reject();
            } else {
                return data.json()
            }
        })
    }
    return (
        <EntityWithNameComponent
            createNewEntity={handleCreateProgramClick}
            updateEntity={handleUpdateProgramClick}
            deleteEntity={handleDeleteProgramClick}
            setActiveEntity={selectProgramClick}
            entityText="Program"
            fetchEntityList={fetchProgramListFunc}
            getUniqueId={program => program.programId}
            getDisplayName={program => program.name}
        />
    );
}