import * as React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import DropDown from '../DropDown';
import { getTokenFromState, getActiveProgramIdFromState } from '../../redux/state-utils'
import { deleteVariant, fetchVariantList } from '../../apiService'
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router';
import { setActiveVariant } from '../../redux/useAction'
import { CreateVariant } from './CreateVariant'
import { UpdateVariant } from './UpdateVariant'
import { Confirm } from '../common/Confirm'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

// Publisher > Program > Variant > Series > Episode
export default function Variant() {
    const [variant, setVariant] = React.useState(null);
    const [variantList, setVariantList] = React.useState([]);
    const [mode, setMode] = React.useState("");
    const programId = useSelector(state => getActiveProgramIdFromState(state))
    const token = useSelector(state => getTokenFromState(state))
    const dispatch = useDispatch()
    let navigate = useNavigate();

    const onCreateVariant = () => {
        return fetchVariantListFunc()
    }

    const onUpdateVariant = () => {
        alert("Updated variant", variant.name)
        return fetchVariantListFunc()
    }

    const onDeleteVariant = () => {
        deleteVariant(variant.variantId, token).then(data => {
            if (data.status !== 200) {
                return Promise.reject()
            }
            console.log("Deleted variantId " + variant.variantId)
        }).then(res => {
            return fetchVariantListFunc()
        }).catch(err => {
            console.error(err.message)
            alert("Something went wrong!!!")
        })
    }

    const fetchVariantListFunc = React.useCallback((currVariant) => {
        return fetchVariantList(programId, token).then(data => {
            if (data.status === 200) {
                return data.json()
            } else {
                return Promise.reject()
            }
        }).then(vList => {
            setVariantList(vList)
            if (currVariant) {
                const v = vList.find(v => v.variantId === currVariant.variantId)
                if (v) {
                    handleSetVariant(v)
                }
            } else {
                handleSetVariant(null)
            }
        }).catch(err => {
            console.error(err.message)
            alert("Something went wrong!!!")
        })
    }, [programId, token])
    React.useEffect(() => {
        fetchVariantListFunc()
    }, [programId, token, fetchVariantListFunc])

    const handleSetVariant = (selectedVariant) => {
        if (selectedVariant) {
            setVariant(selectedVariant)
        } else {
            setVariant(null)
        }
    }

    const selectVariantClick = () => {
        dispatch(setActiveVariant(variant))
        navigate(`/series`)
    }

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={11}>
                <Card variant="outlined">
                    <Grid container spacing={2} justifyContent="center" alignItems="end">
                        <Grid item xs={10}>
                            <Grid container spacing={2} justifyContent="left" alignItems="end">
                                <Grid item xs={6}>
                                    <DropDown id="variant" label="Variant" values={variantList}
                                        value={variant} handleChange={handleSetVariant}
                                        getUniqueId={(option) => option.variantId}
                                        getDisplayName={(option) => option.name}
                                        isError={false}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <AddOutlinedIcon onClick={() => setMode("create")} />
                                </Grid>
                                {variant && <Grid item xs={2}>
                                    <EditOutlinedIcon onClick={() => setMode("update")} />
                                </Grid>}
                                {variant && <Grid item xs={2}>
                                    <DeleteOutlineOutlinedIcon onClick={() => setMode("delete")} />
                                </Grid>}
                                <Grid item xs={6}>
                                    <Button variant="contained" fullWidth
                                        onClick={selectVariantClick}>
                                        Next
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        {mode === "create" && <Grid item xs={10}>
                            <CreateVariant onCreateVariant={onCreateVariant} />
                        </Grid>}
                        {mode === "update" && variant && <Grid item xs={10}>
                            <UpdateVariant onUpdateVariant={onUpdateVariant} variant={variant} />
                        </Grid>}
                        {mode === "delete" && variant && <Grid item xs={10}>
                            <Confirm heading={`Are you sure you want to delete ${variant.name}`}
                                onSuccess={onDeleteVariant}
                                onFailure={() => setMode("")}
                            />
                        </Grid>}
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    );
}