
export function getCallingPlansHost() {
    return "https://int.cell.fish/"
}
export async function authenticate(username, password) {
    const url = `${getCallingPlansHost()}v1/client/authenticate`
    const options = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ username, password }),
    };
    return await fetch(url, options);
}

export async function fetchProgramList(publisherId, token) {
    const url = `${getCallingPlansHost()}v1/client/fetchProgramList?publisherId=${publisherId}`
    const options = {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    };
    return await fetch(url, options);
}

export async function createNewProgram(name, publisherId, token) {
    const url = `${getCallingPlansHost()}v1/client/createProgram`
    const formData = new FormData();
    formData.append("name", name)
    formData.append("publisherId", publisherId)
    const options = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Authorization": "Bearer " + token
        },
        body: formData
    };
    return await fetch(url, options);
}

export async function updateProgram(name, programId, token) {
    const url = `${getCallingPlansHost()}v1/client/updateProgram`
    const formData = new FormData();
    formData.append("name", name)
    formData.append("programId", programId)
    const options = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Authorization": "Bearer " + token
        },
        body: formData
    };
    return await fetch(url, options);
}

export async function deleteProgram(programId, token) {
    const url = `${getCallingPlansHost()}v1/client/deleteProgram`
    const formData = new FormData();
    formData.append("programId", programId)
    const options = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Authorization": "Bearer " + token
        },
        body: formData
    };
    return await fetch(url, options);
}

export async function createVariant(variantName, file, programId, token) {
    const url = `${getCallingPlansHost()}v1/client/createVariant`
    const formData = new FormData();
    formData.append("image", file)
    formData.append("name", variantName)
    formData.append("programId", programId)
    const options = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Authorization": "Bearer " + token
        },
        body: formData
    };
    return await fetch(url, options);
}

export async function updateVariant(variantName, file, programId, variantId, token) {
    const url = `${getCallingPlansHost()}v1/client/updateVariant`
    const formData = new FormData();
    if (file) {
        formData.append("image", file)
    }
    formData.append("name", variantName)
    formData.append("programId", programId)
    formData.append("variantId", variantId)
    const options = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Authorization": "Bearer " + token
        },
        body: formData
    };
    return await fetch(url, options);
}

export async function deleteVariant(variantId, token) {
    const url = `${getCallingPlansHost()}v1/client/deleteVariant`
    const formData = new FormData();
    formData.append("variantId", variantId)
    const options = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Authorization": "Bearer " + token
        },
        body: formData
    };
    return await fetch(url, options);
}

export async function fetchVariantList(programId, token) {
    const url = `${getCallingPlansHost()}v1/client/fetchVariantList?programId=${programId}`
    const options = {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    };
    return await fetch(url, options);
}

export async function fetchSeriesList(variantId, token) {
    const url = `${getCallingPlansHost()}v1/client/fetchSeriesList?variantId=${variantId}`
    const options = {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    };
    return await fetch(url, options);
}

export async function createSeries(name, variantId, token) {
    const url = `${getCallingPlansHost()}v1/client/createSeries`
    const formData = new FormData();
    formData.append("name", name)
    formData.append("variantId", variantId)
    const options = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Authorization": "Bearer " + token
        },
        body: formData
    };
    return await fetch(url, options);
}

export async function updateSeries(name, seriesId, token) {
    const url = `${getCallingPlansHost()}v1/client/updateSeries`
    const formData = new FormData();
    formData.append("name", name)
    formData.append("seriesId", seriesId)
    const options = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Authorization": "Bearer " + token
        },
        body: formData
    };
    return await fetch(url, options);
}

export async function deleteSeries(seriesId, token) {
    const url = `${getCallingPlansHost()}v1/client/deleteSeries`
    const formData = new FormData();
    formData.append("seriesId", seriesId)
    const options = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Authorization": "Bearer " + token
        },
        body: formData
    };
    return await fetch(url, options);
}

export async function createEpisode(seriesId, episodeName, title, text, episodeUrl, token) {
    const url = `${getCallingPlansHost()}v1/client/createEpisode`
    const formData = new FormData();
    formData.append("seriesId", seriesId)
    formData.append("name", episodeName)
    formData.append("title", title)
    formData.append("text", text)
    formData.append("episodeUrl", episodeUrl)
    const options = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Authorization": "Bearer " + token
        },
        body: formData
    };
    return await fetch(url, options);
}

export async function updateEpisode(seriesId, episodeName, title, text, episodeUrl, episodeId, token) {
    const url = `${getCallingPlansHost()}v1/client/updateEpisode`
    const formData = new FormData();
    formData.append("episodeId", episodeId)
    formData.append("seriesId", seriesId)
    formData.append("name", episodeName)
    formData.append("title", title)
    formData.append("text", text)
    formData.append("episodeUrl", episodeUrl)
    const options = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Authorization": "Bearer " + token
        },
        body: formData
    };
    return await fetch(url, options);
}

export async function deleteEpisode(episodeId, token) {
    const url = `${getCallingPlansHost()}v1/client/deleteEpisode`
    const formData = new FormData();
    formData.append("episodeId", episodeId)
    const options = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Authorization": "Bearer " + token
        },
        body: formData
    };
    return await fetch(url, options);
}


export async function fetchEpisodeList(seriesId, token) {
    const url = `${getCallingPlansHost()}v1/client/fetchEpisodeList?seriesId=${seriesId}`
    const options = {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    };
    return await fetch(url, options);
}