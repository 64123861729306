import * as React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { getTokenFromState, getActiveSeriesIdFromState, getActiveVariantImageUrlFromState } from '../../redux/state-utils'
import { updateEpisode } from '../../apiService'
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import RobotImage from '../RobotImage';
import TextField from '@mui/material/TextField';
import { useSelector } from 'react-redux'

export default function UpdateEpisode(props) {
    const episode = props.episode
    const seriesId = useSelector(state => getActiveSeriesIdFromState(state))
    const imageUrl = useSelector(state => getActiveVariantImageUrlFromState(state))
    const token = useSelector(state => getTokenFromState(state))
    const [name, setName] = React.useState(episode.name);
    const [title, setTitle] = React.useState(episode.title);
    const [text, setText] = React.useState(episode.text);
    const [episodeUrl, setEpisodeUrl] = React.useState(episode.episodeUrl);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [formSubmitted, setFormSubmitted] = React.useState(false);
    const onUpdateEpisodeProp = props.onUpdateEpisode
    React.useEffect(() => {
        setName(episode.name);
        setTitle(episode.title);
        setText(episode.text);
        setEpisodeUrl(episode.episodeUrl);
    }, [episode])
    const onUpdateEpisode = () => {
        setFormSubmitted(true)
        if (!name) {
            return;
        }
        updateEpisode(seriesId, name, title, text, episodeUrl, episode.episodeId, token).then(data => {
            if (data.status === 200) {
                return data.json();
            } else {
                return Promise.reject("Error in updateEpisode")
            }
        }).then(res => {
            setErrorMessage("")
            onUpdateEpisodeProp()
        }).catch(err => {
            console.error(err)
            setErrorMessage("Something went wrong!!!")
        })
    }

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={11}>
                <Card variant="outlined">
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={10}>
                            <Typography gutterBottom variant="h6" component="div" align='center' fontWeight="bold">
                                Update EPISODE
                            </Typography>
                        </Grid>
                        <Grid item md={10}>
                            <Card variant="outlined">
                                <Grid container justifyContent="center">
                                    <Grid item xs={6}>
                                        <RobotImage imageUrl={imageUrl} aspectRatio={2.0} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container justifyContent="center">
                                            <Grid item xs={10}>
                                                <Typography gutterBottom variant="h6" component="div" align='center' fontWeight="bold" fontFamily="roboto">
                                                    {title}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Typography gutterBottom variant="h8" component="div" align='center' fontWeight="bold" fontFamily="roboto">
                                                    {text}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                fullWidth={true}
                                id="name"
                                label="Name"
                                type="text"
                                autoComplete="Name"
                                variant="standard"
                                value={name}
                                onChange={(event) => { setName(event.target.value) }}
                                error={formSubmitted && !name}
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                fullWidth={true}
                                id="title"
                                label="Title"
                                type="text"
                                autoComplete="Title"
                                variant="standard"
                                value={title}
                                onChange={(event) => { setTitle(event.target.value) }}
                                error={formSubmitted && !title}
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                fullWidth={true}
                                id="text"
                                label="Text"
                                type="text"
                                autoComplete="Text"
                                variant="standard"
                                value={text}
                                onChange={(event) => { setText(event.target.value) }}
                                error={formSubmitted && !text}
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                fullWidth={true}
                                id="episodeUrl"
                                label="EpisodeUrl"
                                type="text"
                                autoComplete="EpisodeUrl"
                                variant="standard"
                                value={episodeUrl}
                                onChange={(event) => { setEpisodeUrl(event.target.value) }}
                                error={formSubmitted && !episodeUrl}
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <Button variant="contained" endIcon={<SendIcon />} onClick={onUpdateEpisode}>
                                Submit Details
                            </Button>
                        </Grid>
                        {errorMessage && <Grid item xs={10}>
                            <Typography gutterBottom variant="h6" component="div" align='center' color={"red"} fontWeight="bold">
                                {errorMessage}
                            </Typography>
                        </Grid>}
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    );
}