import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { fetchSeriesList, createSeries, deleteSeries, updateSeries } from '../../apiService'
import { setActiveSeries } from '../../redux/useAction'
import { useNavigate } from 'react-router';
import EntityWithNameComponent from '../common/EntityWithNameComponent'
import { getPublisherIdFromState, getActiveVariantIdFromState } from '../../redux/state-utils'

export default function Series() {
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const variantId = useSelector(state => getActiveVariantIdFromState(state))
    const handleCreateSeriesClick = (newSeriesName, token) => {
        return createSeries(newSeriesName, variantId, token)
            .then(data => {
                if (data.status !== 200) {
                    return Promise.reject();
                } else {
                    return data.json()
                }
            }).then(res => {
                selectSeriesClick(res)
            })
    }
    const handleUpdateSeriesClick = (newSeriesName, series, token) => {
        return updateSeries(newSeriesName, series.seriesId, token)
            .then(data => {
                if (data.status !== 200) {
                    return Promise.reject();
                }
                console.log("Updated newSeriesName " + newSeriesName)
            })
    }
    const handleDeleteSeriesClick = (series, token) => {
        return deleteSeries(series.seriesId, token)
            .then(data => {
                if (data.status !== 200) {
                    return Promise.reject();
                }
                console.log("Delete series " + series.seriesId)
            })
    }
    const selectSeriesClick = (series) => {
        dispatch(setActiveSeries(series))
        navigate(`/episode`)
    }
    const fetchSeriesListFunc = (token) => {
        return fetchSeriesList(variantId, token).then(data => {
            if (data.status !== 200) {
                return Promise.reject();
            } else {
                return data.json()
            }
        })
    }
    return (
        <EntityWithNameComponent
            createNewEntity={handleCreateSeriesClick}
            updateEntity={handleUpdateSeriesClick}
            deleteEntity={handleDeleteSeriesClick}
            setActiveEntity={selectSeriesClick}
            entityText="Series"
            fetchEntityList={fetchSeriesListFunc}
            getUniqueId={series => series.seriesId}
            getDisplayName={series => series.name}
        />
    );
}