export const SET_ACTIVE_PROGRAM = "SET_ACTIVE_PROGRAM"
export const setActiveProgram = (program) => {
    return {
        type: SET_ACTIVE_PROGRAM,
        payload: program
    }
}

export const SET_ACTIVE_VARIANT = "SET_ACTIVE_VARIANT"
export const setActiveVariant = (variant) => {
    return {
        type: SET_ACTIVE_VARIANT,
        payload: variant
    }
}

export const SET_ACTIVE_SERIES = "SET_ACTIVE_SERIES"
export const setActiveSeries = (series) => {
    return {
        type: SET_ACTIVE_SERIES,
        payload: series
    }
}

export const SET_ACTIVE_EPSIODE = "SET_ACTIVE_EPSIODE"
export const setActiveEpisode = (episode) => {
    return {
        type: SET_ACTIVE_EPSIODE,
        payload: episode
    }
}

export const SET_PUBLISHER_DETAILS = "SET_PUBLISHER_DETAILS"
export const setPublisherDetails = (data) => {
    return {
        type: SET_PUBLISHER_DETAILS,
        payload: { ...data, lastUpdatedTime: Date.now() }
    }
}

export const REMOVE_ALL_DETAILS = "REMOVE_ALL_DETAILS"
export const removeAllDetails = () => {
    return {
        type: "REMOVE_ALL_DETAILS",
        payload: {}
    }
}