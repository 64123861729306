export const getTokenFromState = ({ root }) => {
    return root.publisher && root.publisher.token
}

export const getPublisherIdFromState = ({ root }) => {
    return root.publisher && root.publisher.publisherId
}

export const getPublisherFromState = ({ root }) => {
    return root.publisher && root.publisher
}

export const getActiveProgramIdFromState = ({ root }) => {
    return root.active && root.active.program && root.active.program.programId;
}

export const getActiveProgramFromState = ({ root }) => {
    return root.active && root.active.program;
}

export const getActiveVariantIdFromState = ({ root }) => {
    return root.active && root.active.variant && root.active.variant.variantId;
}

export const getActiveVariantFromState = ({ root }) => {
    return root.active && root.active.variant;
}

export const getActiveVariantImageUrlFromState = ({ root }) => {
    return root.active && root.active.variant && root.active.variant.imageUrl;
}

export const getActiveSeriesIdFromState = ({ root }) => {
    return root.active && root.active.series && root.active.series.seriesId;
}

export const getActiveSeriesFromState = ({ root }) => {
    return root.active && root.active.series;
}