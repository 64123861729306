import * as React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { getTokenFromState, getActiveProgramIdFromState } from '../../redux/state-utils'
import { updateVariant } from '../../apiService'
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import RobotImage from '../RobotImage';
import FileUpload from "react-material-file-upload";
import TextField from '@mui/material/TextField';
import { useSelector } from 'react-redux'

export function UpdateVariant(props) {
    const variant = props.variant
    const programId = useSelector(state => getActiveProgramIdFromState(state))
    const token = useSelector(state => getTokenFromState(state))
    const [name, setName] = React.useState(variant.name);
    const [imageUrl, setImageUrl] = React.useState(variant.imageUrl);
    const [files, setFiles] = React.useState([]);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [formSubmitted, setFormSubmitted] = React.useState(false);
    const onUpdateVariantProp = props.onUpdateVariant
    React.useEffect(() => {
        setName(variant.name)
        setImageUrl(variant.imageUrl)
    }, [variant])
    const onUpdateVariant = () => {
        setFormSubmitted(true)
        if (!name || !files || !files.length === 0) {
            return;
        }
        updateVariant(name, files[0], programId, variant.variantId, token).then(data => {
            if (data.status === 200) {
                return data.json();
            } else {
                return data.text().then(d => {
                    return Promise.reject(d)
                })
            }
        }).then(res => {
            return onUpdateVariantProp(res)
        }).catch(err => {
            alert(err)
            setErrorMessage("Something went wrong!!!")
        })
    }

    return <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10}>
            <Typography gutterBottom variant="h6" component="div" align='center' fontWeight="bold">
                UPDATE VARIANT
            </Typography>
        </Grid>
        <Grid item xs={6}>
            <Card variant="outlined">
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        <RobotImage file={files[0]} imageUrl={files[0] ? undefined : imageUrl} />
                    </Grid>
                </Grid>
            </Card>
        </Grid>
        <Grid item xs={6}>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={10}>
                    <TextField
                        fullWidth={true}
                        id="name"
                        label="Name"
                        type="text"
                        autoComplete="Name"
                        variant="standard"
                        value={name}
                        onChange={(event) => { setName(event.target.value) }}
                        error={formSubmitted && !name}
                    />
                </Grid>
                <Grid item xs={10}>
                    <FileUpload value={files} onChange={setFiles} accept="image/jpg, image/gif, image/jpeg" error={formSubmitted && (!files || files.length === 0)} />
                </Grid>
                <Grid item xs={10}>
                    <Button variant="contained" endIcon={<SendIcon />} onClick={onUpdateVariant}>
                        Submit Details
                    </Button>
                </Grid>
                {errorMessage && <Grid item xs={10}>
                    <Typography gutterBottom variant="h6" component="div" align='center' color={"red"} fontWeight="bold">
                        {errorMessage}
                    </Typography>
                </Grid>}
            </Grid>
        </Grid>
    </Grid>
}