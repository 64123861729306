import React from 'react';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import { useLocation } from "react-router-dom";
import {
    getActiveSeriesFromState, getActiveProgramFromState, getActiveVariantFromState, getPublisherFromState
} from './../redux/state-utils'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';


export default function TopNavLinks() {
    let location = useLocation();
    const [links, setLinks] = React.useState([])
    const pathname = location.pathname;
    let navigate = useNavigate();
    const publisher = useSelector(state => getPublisherFromState(state))
    const program = useSelector(state => getActiveProgramFromState(state))
    const variant = useSelector(state => getActiveVariantFromState(state))
    const series = useSelector(state => getActiveSeriesFromState(state))
    const publisherLink = { name: publisher && publisher.name, path: "program" }
    const programLink = { name: program && program.name, path: "variant" }
    const variantLink = { name: variant && variant.name, path: "series" }
    const seriesLink = { name: series && series.name, path: "episode" }
    React.useEffect(() => {
        if (pathname === "/program") {
            setLinks([publisherLink])
        } else if (pathname === "/variant") {
            setLinks([publisherLink, programLink])
        } else if (pathname === "/series") {
            setLinks([publisherLink, programLink, variantLink])
        } else if (pathname === "/episode") {
            setLinks([publisherLink, programLink, variantLink, seriesLink])
        }
    }, [pathname])
    return (
        <Card>
            <Grid container spacing={2} justifyContent="center">
                {links.map((link, i) => {
                    return <Grid item md={3} key={link.path + link.name}>
                        <Grid container spacing={2}>
                            <Grid item xs={9}>
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={() => {
                                        navigate("/" + link.path)
                                    }}
                                >
                                    <b>{link.name && link.name.toUpperCase()}</b>
                                </Link>
                            </Grid>
                            {i !== links.length - 1 && <Grid item xs={3}>
                                <ArrowForwardIosOutlinedIcon fontSize="small" color="primary"></ArrowForwardIosOutlinedIcon>
                            </Grid>}
                        </Grid>
                    </Grid>
                })}

                {/* <Grid item xs={2}>
                    <Link
                        component="button"
                        variant="body2"
                        onClick={() => {
                            console.info("I'm a button.");
                        }}
                    >
                        Program
                    </Link>
                </Grid>
                <Grid item xs={1}>
                    <Link
                        component="button"
                        variant="body2"
                        underline="none"
                        onClick={() => {
                            console.info("I'm a button.");
                        }}
                    >
              >
                    </Link>
                </Grid>
                <Grid item xs={2}>
                    <Link
                        component="button"
                        variant="body2"
                        onClick={() => {
                            console.info("I'm a button.");
                        }}
                    >
                        Variant
                    </Link>
                </Grid>
                <Grid item xs={1}>
                    <Link
                        component="button"
                        variant="body2"
                        underline="none"
                        onClick={() => {
                            console.info("I'm a button.");
                        }}
                    >
              >
                    </Link>
                </Grid>
                <Grid item xs={2}>
                    <Link
                        component="button"
                        variant="body2"
                        onClick={() => {
                            console.info("I'm a button.");
                        }}
                    >
                        Series
                    </Link>
                </Grid>
                <Grid item xs={1}>
                    <Link
                        component="button"
                        variant="body2"
                        underline="none"
                        onClick={() => {
                            console.info("I'm a button.");
                        }}
                    >
              >
                    </Link>
                </Grid>
                <Grid item xs={2}>
                    <Link
                        component="button"
                        variant="body2"
                        onClick={() => {
                            console.info("I'm a button.");
                        }}
                    >
                        Episode
                    </Link>
                </Grid> */}
            </Grid>
        </Card>
    );
}