import './App.css';
import React from 'react';
import Login from './components/Login';
import Program from './components/program/Program';
import Grid from '@mui/material/Grid';
import Series from './components/series/Series';
import TopNavLinks from './components/TopNavLinks';
import Episode from './components/episode/Episode';
import ResponsiveAppBar from './components/AppBar';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Routes, Route, Navigate } from "react-router-dom";
import Variant from './components/variant/Variant';
import { useSelector, useDispatch } from 'react-redux'
import { setPublisherDetails, removeAllDetails } from './redux/useAction'
import { getTokenFromState } from './redux/state-utils'
import {
  getActiveSeriesFromState, getActiveProgramFromState, getActiveVariantFromState, getPublisherFromState
} from './redux/state-utils'

function App() {
  const [loading, setLoading] = React.useState(true);
  const token = useSelector(state => {
    return getTokenFromState(state)
  })
  const dispatch = useDispatch()
  const publisher = useSelector(state => getPublisherFromState(state))
  React.useEffect(() => {
    setLoading(false);
    if (!publisher || !publisher.lastUpdatedTime || Date.now() - publisher.lastUpdatedTime > 60 * 60 * 1000) {
      dispatch(removeAllDetails())
    }
  }, [])
  const logout = () => {
    dispatch(removeAllDetails())
  }
  const onLogin = (t) => {
    dispatch(setPublisherDetails(t))
  }

  return (
    <React.StrictMode>
      <ResponsiveAppBar settings={
        token ? [{ name: "Logout", onClick: logout }] : []
      }></ResponsiveAppBar>
      {loading && <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>}
      {!loading && !token && <Routes>
        <Route path="/" element={<Login onLogin={onLogin}></Login>} />
        <Route
          path="*"
          element={<Navigate to="/" replace />}
        />
      </Routes>}
      {!loading && token && <LoggedInComponent />}
    </React.StrictMode >
  );
}

export default App;


function LoggedInComponent() {
  const publisher = useSelector(state => getPublisherFromState(state))
  const program = useSelector(state => getActiveProgramFromState(state))
  const variant = useSelector(state => getActiveVariantFromState(state))
  const series = useSelector(state => getActiveSeriesFromState(state))
  return <Grid container spacing={2} justifyContent="center">
    <Grid item xs={11}>
    </Grid>
    <Grid item xs={11}>
      <TopNavLinks />
    </Grid>
    <Grid item xs={12}>
      <Routes>
        <Route path="/program" element={<Program />} />
        <Route path="/variant" element={program ? <Variant /> : <NavigateToProgram path="program" />} />
        <Route path="/series" element={(program && variant) ? <Series /> : <NavigateToProgram path="variant" />} />
        <Route path="/episode" element={(program && variant && series) ? <Episode /> : <NavigateToProgram path="series" />} />
        <Route
          path="*"
          element={<NavigateToProgram path="program" />}
        />
      </Routes>
    </Grid>
  </Grid>
}

function NavigateToProgram(props) {
  return <Navigate to={"/" + props.path} replace />
}