import Box from '@mui/material/Box';
const logoSrc = "/static/images/logo.png"

const LogoIcon = () => {
    return <Box
        component="img"
        sx={{
            height: 64,
        }}
        alt="Cellfish logo"
        src={logoSrc}
    />
}

export default LogoIcon;