import { LOCATION_CHANGE } from 'react-router-redux'
import { SET_PUBLISHER_DETAILS, REMOVE_ALL_DETAILS, SET_ACTIVE_PROGRAM, SET_ACTIVE_VARIANT, SET_ACTIVE_SERIES, SET_ACTIVE_EPSIODE } from './useAction'
const initialState = {
    token: ""
}

// Use the initialState as a default value
export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case SET_PUBLISHER_DETAILS:
            return { ...state, publisher: action.payload }
        case REMOVE_ALL_DETAILS:
            return {}
        case SET_ACTIVE_PROGRAM:
            return { ...state, active: { program: action.payload } }
        case SET_ACTIVE_VARIANT:
            return { ...state, active: { program: state.active.program, variant: action.payload } }
        case SET_ACTIVE_SERIES:
            return {
                ...state, active: {
                    program: state.active.program,
                    variant: state.active.variant,
                    series: action.payload
                }
            }
        case SET_ACTIVE_EPSIODE:
            return {
                ...state, active: {
                    program: state.active.program,
                    variant: state.active.variant,
                    series: state.active.series,
                    episode: action.payload
                }
            }
        case LOCATION_CHANGE:
            console.warn('LOCATION_CHANGE from your reducer', action)
            return state
        default:
            return state
    }
}